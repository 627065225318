import { useQueryClient } from "@tanstack/react-query";
import SignalRConnector from '../signalr-connection';
import { useEffect } from "react";

/** hook to auto reconnect when websocket needs to reconnect, on focus events, and clear cache on freeze events
 * https://developer.chrome.com/docs/web-platform/page-lifecycle-api#events
*/
export function useInactivityHandler() {
    const queryClient = useQueryClient();
    
    // clear cache on reload event - happens when signalR connection has been dead for a while
    const { reconnectIfStale, setStale } = SignalRConnector();

    async function onFocusHandler () {
      const requireReload = await reconnectIfStale();
      if (requireReload) {
        queryClient.resetQueries();
      }
    }

    function onFreezeHandler () {
      console.log('onFreezeHandler handler called')
      setStale();
    }

    function onResumeHandler () {
      console.log('onResumeHandler handler called')
      queryClient.resetQueries();
    }

    useEffect(() => {
      document.addEventListener("resume", onResumeHandler)
      document.addEventListener("freeze", onFreezeHandler);
      window.addEventListener('focus', onFocusHandler);
      return () => {
        document.removeEventListener("resume", onResumeHandler)
        document.removeEventListener("freeze", onFreezeHandler);
        window.removeEventListener('focus', onFocusHandler);
      }
    }, []);
}