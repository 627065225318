import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CalendarSettings, GraphSettings, ItemTypes, NewsfeedSettings, WatchmedierSettings, selectOptions } from '../../types/componentSettings'
import { Form } from "react-bootstrap";

import { useLayoutStore } from "../../stores/layout";
import { NewsfeedEdit } from "./editComponents/NewsfeedEdit";
import { GraphEdit } from "./editComponents/GraphEdit";
import { WatchmedierfeedEdit } from "./editComponents/WatchmedierEdit";
import { CalendarEdit } from "./editComponents/CalendarEdit";
import { isMobile } from "react-device-detect";

export const EditModal = () => {
    const layout = useLayoutStore();
    const column = layout.columns.find(x => x.id === layout.modalColumnId);
    const existingItem = layout.modalItemIndex === undefined ? undefined : column?.items[layout.modalItemIndex];

     const handleChangeItemType = (event:React.ChangeEvent<HTMLSelectElement>) => {
         const val = Number(event.target.value);
         const itemType = val as ItemTypes;
         layout.modalChangeType(itemType);
     }

     const saveDisabled = () => {
        if (!layout.modalItemSettings) return true;

        switch (layout.modalItemType) {
            case ItemTypes.GRAPH:
                const gs = layout.modalItemSettings as GraphSettings;
                return !(gs.graphs?.length > 0)
            case ItemTypes.NEWSFEED:
                const s = layout.modalItemSettings as NewsfeedSettings;
                return !(s.marketwireNyheder
                     || s.marketwireFlash
                     || s.borsNasdaq
                     || s.borsFirstNorth
                     || s.borsSpotlight
                     || s.borsAndre
                     || s.aktier
                     || s.makro
                     || s.avisuddrag
                     || s.tophistorier)
            case ItemTypes.WATCHMEDIERFEED:
                const wms = layout.modalItemSettings as WatchmedierSettings;
                return wms.feeds.length === 0;
            case ItemTypes.CALENDAR:
                const cs = layout.modalItemSettings as CalendarSettings;
                return cs.filter.length === 0;
            default:
                return false;
        }
    }

    return (
        <Modal
            show={layout.modalVisible}
            onHide={layout.modalClose}
            onExited={layout.modalClose}
            backdrop="static"
            keyboard={false}
        >
             <Modal.Header closeButton>
                 <Modal.Title>
                    { existingItem ? 'Rediger komponent' : 'Opret nyt komponent' }
                </Modal.Title>
             </Modal.Header>
             <Modal.Body>
                {
                    isMobile ? null : 
                    <Form.Select className="mb-3" color="primary" value={layout.modalItemType} onChange={handleChangeItemType}>
                        <option disabled>Vælg komponent</option>
                        {
                           selectOptions.map((option, i) => (
                               <option key={i} value={option.value}>{option.label}</option>
                           ))
                        };
                    </Form.Select>
                }
                 

                 {/* Setting controls for the currently selected item type */}
                 <div>
                     {
                         layout.modalItemType === ItemTypes.NEWSFEED ?
                         <NewsfeedEdit /> :
                         layout.modalItemType === ItemTypes.STOCKFEED ?
                         <div></div> :
                         layout.modalItemType === ItemTypes.GRAPH ?
                         <GraphEdit /> :
                         layout.modalItemType === ItemTypes.WATCHMEDIERFEED ?
                         <WatchmedierfeedEdit /> :
                         layout.modalItemType === ItemTypes.CALENDAR ?
                         <CalendarEdit /> : null
                     }
                 </div>

             </Modal.Body>
             <Modal.Footer>
                 <Button variant="secondary" onClick={layout.modalClose}>
                     Luk
                 </Button>
                 {<Button variant="success" disabled={saveDisabled()} onClick={layout.modalSave}>
                     Gem
                 </Button>}
             </Modal.Footer>
             </Modal>
           );
}