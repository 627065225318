import { create } from 'zustand'
import { CreateNewSettingInstance, ItemSettings, ItemTypes, NewsfeedSettings, TempItemSettings } from '../types/componentSettings';
import { v4 as uuid } from 'uuid';
import { current, produce } from 'immer'
import { klona } from 'klona';
import { WatchMedierFeeds } from '../services/watchmedieService';
import { GetAllEnumValues } from '../helpers/miscHelper';

import { isMobile } from 'react-device-detect'
import { AuthContextProps } from 'bff-auth-lib/dist/lib/AuthContext';
import { AuthHelper } from '../helpers/authHelper';
export const layoutVersion = 1;
const totalColumnCount:number = 6;
const localStorageKey = 'layout';


let currentLayoutIsDirty:boolean = true;
/** the type of a component along with its own settings (e.g. which news types are enabled if itemType is NewsFeed) */
export interface ComponentInfo {
  itemType: ItemTypes,
  itemSettings?: any,
}
export interface LayoutItem {
  id: string,
  info: ComponentInfo,
}

export interface LayoutColumn {
  id: string,
  items: LayoutItem[]
}

export interface LayoutStateValues {
  layoutId?: number,

  version: number,
  /** determine whether the layout with current layoutId has been modified by user changees or importing the layout itself */
  columns: LayoutColumn[];
}

/** contains all data related to the current window/column configuration, as well as individual component settings*/
export interface LayoutState extends LayoutStateValues {
  initLayout: (auth:AuthContextProps) => void,
  unsetLayoutId: () => void,
  importLayout: (auth:AuthContextProps, layoutb64:string, layoutId?:number) => void;
  resetLayout: () => void;
  updateColumns: (newColumns:LayoutColumn[]) => void;
  updateColumnItem: (columnId: string, itemIndex:number, newItem:LayoutItem) => void;
  updateColumnItemSettings: (columnId: string, itemIndex:number, newSettings:ItemSettings) => void;
  removeColumnItem: (columnId: string, itemIndex:number) => void;
  addColumnItem: (columnId: string, newItem:LayoutItem) => void;

  // for modal to add and edit components
  modalVisible: boolean,
  modalShow: (columnId:string, itemIndex?:number) => void,
  modalClose: () => void,
  modalSave: () => void,
  modalChangeType: (newType:ItemTypes) => void;
  modalColumnId: string, // the currently editing items column, or col to add new item
  modalItemIndex?: number, // the currently editing items index, or undefined if new item
  modalItemSettings?: ItemSettings, // the settings being edited (not a reference to existing settings)
  modalItemType: ItemTypes, // the currently selected ItemType to edit/add (default NEWSFEED)
  modalChangeSettings: (newSettings: ItemSettings) => void,
  modalChangeSettingValue: (propertyName:string, propertyValue: any) => void,
  tempSettingsChangeDate: (columnId:string, itemIndex:number, date:Date|undefined) => void,
  
}

/** the presets used when a new MOBILE user starts the SPA */
const mobilePreset = {
  version: layoutVersion,
  columns: [
    {
      id: uuid(),
      items: [
        { id: uuid(), info: { itemType: ItemTypes.NEWSFEED, itemSettings: { ...CreateNewSettingInstance(ItemTypes.NEWSFEED), marketwireNyheder: true, } }, },
        { id: uuid(), info: { itemType: ItemTypes.STOCKFEED, itemSettings: CreateNewSettingInstance(ItemTypes.STOCKFEED) } },
        { id: uuid(), info: { itemType: ItemTypes.WATCHMEDIERFEED, itemSettings: { ...CreateNewSettingInstance(ItemTypes.WATCHMEDIERFEED), feeds: GetAllEnumValues(WatchMedierFeeds), }}},
        { id: uuid(), info: { itemType: ItemTypes.CALENDAR, itemSettings: CreateNewSettingInstance(ItemTypes.CALENDAR) } },
      ]
    }
  ]
};
/** the presets used when a new DESKTOP user starts the SPA */
const desktopPreset:LayoutStateValues  = {
  version: layoutVersion,
  columns: [
    {
      id: uuid(),
      items: [
        { id: uuid(), info: { itemType: ItemTypes.GRAPH, itemSettings: { ...CreateNewSettingInstance(ItemTypes.GRAPH) } }, },
        { id: uuid(), info: { itemType: ItemTypes.STOCKFEED, itemSettings: CreateNewSettingInstance(ItemTypes.STOCKFEED) } },
      ]
    },
    {
      id: uuid(),
      items: [
        { id: uuid(), info: { itemType: ItemTypes.NEWSFEED, itemSettings: { ...CreateNewSettingInstance(ItemTypes.NEWSFEED), marketwireNyheder: true, } }, },
        { id: uuid(), info: { itemType: ItemTypes.NEWSFEED, itemSettings: { ...CreateNewSettingInstance(ItemTypes.NEWSFEED), marketwireFlash: true, } }, },
      ]
    },
    {
      id: uuid(),
      items: [
        { id: uuid(), info: { itemType: ItemTypes.NEWSFEED, itemSettings: { ...CreateNewSettingInstance(ItemTypes.NEWSFEED), borsNasdaq: true, } }, },
      ]
    },
    {
      id: uuid(),
      items: [
        { id: uuid(), info: { itemType: ItemTypes.WATCHMEDIERFEED, itemSettings: { ...CreateNewSettingInstance(ItemTypes.WATCHMEDIERFEED), feeds: GetAllEnumValues(WatchMedierFeeds),  } }, },
      ]
    },
  ],
};

/** we always need to have 6 columns total, but this may change in the future.
 * can also be used on saved layouts if they have saved 6 and we change it to 5, then it will pop the 6th column.
 * also responsible for fixing settings, if user is loading an old layout that doesnt have a set value for new settings properties
 */
const fixStateValuesBeforeLoad = (loadedState:LayoutStateValues):LayoutStateValues => {
  if (loadedState.version != layoutVersion) {
    throw new Error("Saved layout version is different from app version");
  }
  while (loadedState.columns.length > totalColumnCount) {
    loadedState.columns.pop();
  }
  while (loadedState.columns.length <= totalColumnCount) {
    loadedState.columns.push(
      {
        id: uuid(),
        items: []
      }
    )
  }

  // fix settings
  loadedState.columns.forEach((col, i) => {
    col.items.forEach((item, j) => {
      const presetSettings = CreateNewSettingInstance(item.info.itemType);
      item.info.itemSettings = {
        ...presetSettings,
        ... item.info.itemSettings
      }
    })
  })

  return loadedState;
}

/** tries to load layout from localStorage if it exists and can be fixed (in case layout has been updated)
 * otherwise loads the layout preset
 */
const loadLayout = () => {
  const ls = localStorage.getItem(localStorageKey);
  if (ls) {
    try {
      const parsedLayout = JSON.parse(ls);
      const fixed = fixStateValuesBeforeLoad(parsedLayout as LayoutStateValues);
      return fixed;
    } catch (error) {

    }
  }

  return fixStateValuesBeforeLoad(isMobile ? mobilePreset: desktopPreset);
}

export const useLayoutStore = create<LayoutState>()((set) => ({
  columns: [],
  version: layoutVersion,
  initLayout: (auth:AuthContextProps) => set((state) => {
    const load = loadLayout();
    const authLayout = AuthHelper.fixLayoutBasedOnUserRoles(auth, load);
    return {
      ...state,
      ...authLayout,
    };
  }),
  unsetLayoutId: () => set((state) => {
    return {...state, layoutId: undefined}
  }),
  tempSettingsChangeDate: (columnId:string, itemIndex:number, date:Date|undefined) => set((state) => {
    return produce(state, draft => {
      const colIndex = state.columns.findIndex(x => x.id === columnId)
      if (colIndex !== -1) {
        const itemSettings = (draft.columns[colIndex].items[itemIndex].info.itemSettings as ItemSettings);
        if (itemSettings.temp) itemSettings.temp.date = date;
        else {
          itemSettings.temp = { date }
        }
      }
    })
  }),
  updateColumns: ( newColumns: LayoutColumn[]) => 
    set((state) => ({
      ...state,
      columns: newColumns
    })),
  updateColumnItem: (columnId: string, itemIndex:number, newItem:LayoutItem) => set((state) => {
    const newColumns = [...state.columns];
    const colIndex = newColumns.findIndex(x => x.id === columnId)
    if (colIndex !== -1) {
      newColumns[colIndex].items[itemIndex] = newItem;
    }
    return {
      ...state,
      columns: newColumns
    }
  }),
  updateColumnItemSettings: (columnId: string, itemIndex:number, settings:ItemSettings) => set((state) => {
    return produce(state, draft => {
      const colIndex = state.columns.findIndex(x => x.id === columnId)
      if (colIndex !== -1) {
        draft.columns[colIndex].items[itemIndex].info.itemSettings = settings;
      }
    })
  }),
  removeColumnItem: (columnId: string, itemIndex:number) => set((state) => {
    return produce(state, draft => {
      const colIndex = state.columns.findIndex(x => x.id === columnId)
      if (colIndex !== -1) {
        draft.columns[colIndex].items.splice(itemIndex, 1);
      }
    });
  }),
  addColumnItem: (columnId: string, newItem:LayoutItem) => set((state) => {
    const newColumns = [...state.columns];
    const colIndex = newColumns.findIndex(x => x.id === columnId)
    if (colIndex !== -1) {
      newColumns[colIndex].items.push(newItem);
    }

    return {
      ...state,
      columns: newColumns
    }
  }),
  importLayout: (auth:AuthContextProps, layoutb64:string, layoutId?:number) => set((state) => {
    const jsonString = b64_to_utf8(layoutb64);
    const newState:LayoutStateValues = JSON.parse(jsonString);
    
    
    if (newState.version != layoutVersion) {
      // do not change or do anything if versions differ
      throw new Error('Layoutkode version matcher ikke nuværende version.')
    }
    if (layoutId) {
      currentLayoutIsDirty = false;
    }
    else {
      currentLayoutIsDirty = true;
    }
    const authState = AuthHelper.fixLayoutBasedOnUserRoles(auth, newState);
    return {
      ...state,
      ...authState,
      layoutId: layoutId,
    }
  }),
  modalVisible: false,
  modalColumnId: '',
  modalItemType: ItemTypes.NEWSFEED,
  modalClose: () => set((state) => ({
    ...state,
    modalVisible: false,
  })),
  modalShow: (columnId:string, itemIndex?:number) => set((state) => {
    const column = state.columns.find(x => x.id === columnId);
    if (!column) return { ... state };
    const existingItem = itemIndex !== undefined ? column.items[itemIndex] : undefined;
    const newSettings = existingItem
    ? {
        ... CreateNewSettingInstance(existingItem.info.itemType),
        ... klona(existingItem.info.itemSettings) 
       }
     : new NewsfeedSettings()
    
    const newType = existingItem ? existingItem.info.itemType : ItemTypes.NEWSFEED;
    
    return {
      ...state,
      modalItemType: newType,
      modalColumnId: columnId,
      modalItemIndex: itemIndex,
      modalItemSettings: newSettings,
      modalVisible: true,
      
    }
  }),
  modalSave: () => set((state) => {
    const newItem:LayoutItem = {
      id: uuid(),
      info: {
        itemType: state.modalItemType,
        itemSettings: state.modalItemSettings
      }
    }
    return (
      produce(state, draftState => {

        for (let i = 0; i < state.columns.length; i++) {
          const col = draftState.columns[i];
          if (col.id === draftState.modalColumnId) {
            if (state.modalItemIndex !== undefined) {
              col.items[state.modalItemIndex] = newItem;
            }
            else {
              col.items.push(newItem);
            }
            break;
          }
          
        }
        draftState.modalVisible = false;
        draftState.modalColumnId = '',
        draftState.modalItemIndex = undefined,
        draftState.modalItemType = ItemTypes.NEWSFEED,
        draftState.modalItemSettings = new NewsfeedSettings()
      }));
  }),
  modalChangeType: (newType:ItemTypes) => set((state) => {
    const column = state.columns.find(x => x.id === state.modalColumnId);
    if (!column) return { ... state };
    const existingItem = state.modalItemIndex !== undefined ? column.items[state.modalItemIndex] : undefined;
    // set default settings, or the settings the original item has if editing existing item
    const newSettings = existingItem && existingItem.info.itemType === newType
    ? {
        ... CreateNewSettingInstance(existingItem.info.itemType),
        ... klona(existingItem.info.itemSettings) 
       }
     : CreateNewSettingInstance(newType)
    return {
      ...state,
      modalItemSettings: newSettings,
      modalItemType: newType,
    }
  }),
  modalChangeSettings: (newSettings:ItemSettings) => set((state) => {
    return {
      ...state,
      modalItemSettings: newSettings
    }
  }),
  modalChangeSettingValue: (propertyName:string, propertyValue:any) => set((state) => {
    if (state.modalItemSettings === undefined) throw new Error("No settings to change, modalItemSettings is undefined");

    return {
      ...state,
      modalItemSettings: {
        ...state.modalItemSettings,
        [propertyName]: propertyValue,
      },
    }
  }),
  resetLayout: () => set((state) => {
    localStorage.removeItem(localStorageKey);
    return {
      ...fixStateValuesBeforeLoad(klona(desktopPreset))
    }
  }),
}))


function removeTemporary(state:LayoutState) {
  const cleanedState = klona({ layoutId: state.layoutId, columns: state.columns, version: state.version });

  for (let i = 0; i < cleanedState.columns.length; i++) {
    const col = cleanedState.columns[i];
    for (let j = 0; j < col.items.length; j++) {
      const itemSettings = col.items[j].info.itemSettings;
      delete itemSettings.temp;
    }
  }
  return cleanedState;
}

//subscribe to store changes, update localStorage when changed
useLayoutStore.subscribe((state) => {
  if (!state.columns.length) {
    console.warn('tried to save layout with no columns')
    return;
  }
  localStorage.setItem(localStorageKey, JSON.stringify(removeTemporary(state)));
  if (currentLayoutIsDirty) {
    if (state.layoutId) {
      //if we don't check before setting, it will loop forever since we subscribe to changes to the state
      state.unsetLayoutId()
    }
  }
  else {
    currentLayoutIsDirty = true;
  }
})

/** exports the users current layout setup as a base64 string */
export const exportLayout = () : string => {
  const layoutStore = useLayoutStore.getState();
  const cleanedState = removeTemporary(layoutStore);

  const jsonString = JSON.stringify(cleanedState)
  const b64Str = utf8_to_b64(jsonString);
  return b64Str;
}

export function utf8_to_b64(str:string) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

export function b64_to_utf8(str:string) {
  return decodeURIComponent(escape(window.atob(str)));
}