
import { Button, CloseButton, ListGroup, Modal } from 'react-bootstrap';
import { useStockWindowStore }  from '../stores/stockwindow'
import ShareService, { GraphData, GraphPoint, GraphRange, ICompany, ShareChangeType, ShareCompany } from '../services/shareService';
import React, { StyleHTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';
import { useInfiniteQuery, useQueries, useQuery } from '@tanstack/react-query';
import { useNotifyStore } from '../stores/notify';
import StockChart from './shared/StockChart';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { graphColors, graphRangeButtons } from '../stores/stockwindow'
import { getChange, getGraphPriceChangeClass, getPercentChange, getPriceChangeClass, parsePrice, shortenPrice } from '../helpers/shareHelper';
import NewsService, { INewsDTO, INewsShort, NewsFilterFlags } from '../services/newsService';
import { useInView } from 'react-intersection-observer';
import { useDebounceEffect } from '@better-hooks/performance';
import { toLocalDateTimeStr, toLocalDateTimeStrRange } from '../helpers/dateHelper';
import { useVirtualizer, useWindowVirtualizer } from '@tanstack/react-virtual'
import { sum } from 'd3-array';
import { ReaderTypes, useReaderStore } from '../stores/reader';
import { GraphQueryKey } from './items/Graph';
import { useSignals } from '@preact/signals-react/runtime';
import { NewNewsfeedItems } from './items/Newsfeed'
import { isMobile } from 'react-device-detect';
export const StockWindow = () => {
    useSignals();

    const state = useStockWindowStore();
    const showReader = useReaderStore(state => state.show);
    const notifyCreate = useNotifyStore(state => state.create)
    const [range, setRange] = React.useState(GraphRange.DAY_1);
    const bodyRef = useRef<HTMLDivElement>(null);
    const { ref: inViewRef, inView } = useInView({
        initialInView: false,
    })

    /** this query should be cached, since its used in the Stockfeed */
    const sharesQuery = useQuery<ICompany[]>({
        queryKey: ['getSimpelShare', state.marketIndex],
        queryFn: async () => { 
            try {
                return await ShareService.GetListSimpel(state.marketIndex) 
            } catch (error:any) {
                notifyCreate({
                    message: error?.message,
                    bg: 'danger',
                });
                throw error;
            }
        },
        staleTime: 1 * 60_000,
        enabled: state.visible
    })

    const companyShareData = sharesQuery.data?.find(x => x.isin == state.isin);

    // close window on escape key
    React.useEffect(() => {
        function handleEscapeKey(event: KeyboardEvent) {
          if (event.code === 'Escape') {
            state.close();
          }
        }

        document.addEventListener('keydown', handleEscapeKey)
        return () => document.removeEventListener('keydown', handleEscapeKey)
      }, [])

    const getButtonVariant = (gr:GraphRange) => {
        return (gr === range) ? 'primary' : 'outline-dark'
    }

    // to show date of news item in graph when hovering over it in newslist below
    const [hoverDate, setHoverDate] = useState<string|undefined>()
    const hoverNewsDate = hoverDate;

    const renderedData = (data:INewsShort[]|undefined) => {
        return data?.map((item, index) => {
            return (
                <li key={item.id + index}
                    onMouseEnter={() => setHoverDate(item.dateInserted)}
                    onClick={() => { showReader(ReaderTypes.NEWSFEED, item.id); state.close()} }
                    className={`list-group-item list-group-item-action d-flex p-2 justify-content-between fs-7 ${NewNewsfeedItems.value.has(item.id) && 'bg-animate-insert'}`}
                >
                    <span>
                        {item.finansHeadline}
                    </span>
                    <span className="text-muted ms-1" style={{minWidth: 'max-content'}}>
                        {toLocalDateTimeStr(item.dateInserted)}
                    </span>
                </li>
            )
        })
    }

    const handleClickGraphRangeButton = (gr:GraphRange) => {
        setRange(gr);
    }

    const fetchGraphData = async (isin:string):Promise<GraphData> => {
        try {
            return await ShareService.GetGraphData(isin, range) 
        } catch (error:any) {
            notifyCreate({
                message: error?.message,
                bg: 'danger',
            });
            throw error;
        }
    };
    const graphQueryKey:GraphQueryKey = { isin: state.isin, graphRange: range }
    const graphQuery = useQuery<GraphData>({
        queryKey: ['graphData', graphQueryKey],
        queryFn: async () => await fetchGraphData(state.isin),
        staleTime: 5 * 60_000,
        enabled: state.visible,
    },)

    const stockInfoQuery = useQuery<ShareCompany>({
        queryKey: ['getCompanyHighLowAndChanges', state.isin],
        queryFn: async () => { 
            try {
                return await ShareService.GetCompanyHighLowAndChanges(state.isin) 
            } catch (error:any) {
                notifyCreate({
                    message: error?.message,
                    bg: 'danger',
                });
                throw error;
            }
        },
        staleTime: 1 * 60_000,
        enabled: state.visible,
    })
    
    const stockInfoQueryData = stockInfoQuery.data;
    const highLowToday = stockInfoQueryData?.highLow.find(x => x.highLowType === 0);
    const highLow52Weeks = stockInfoQueryData?.highLow.find(x => x.highLowType === 1);

    const getChangeType = (t:ShareChangeType) => {
        return stockInfoQueryData?.changes.find(x => x.changeType == t);
    }

    const changeToday = getChangeType(ShareChangeType.DAGENS_AENDRING); //not sure if this should be used
    const changeYTD = getChangeType(ShareChangeType.AARETS_AENDRING);
    
    const baseDTO:INewsDTO = {
        myStocks: false,
        companyIds: [],
        isin: [state.isin],
        filterFlags: (
            NewsFilterFlags.MARKETWIRE_NYHEDER |
            // ingen flash
            NewsFilterFlags.BORS_NASDAQ | 
            NewsFilterFlags.BORS_FIRST_NORTH | 
            NewsFilterFlags.BORS_SPOTLIGHT |
            NewsFilterFlags.BORS_ANDRE
            ),
        limit: 15
    }

    const newsQuery = useInfiniteQuery<INewsShort[], Error>({
        queryKey: ['newsList', state.isin], 
        queryFn: async ({pageParam = baseDTO}) => {
            try {
                return await NewsService.GetList(pageParam)
            } catch (error:any) {
                notifyCreate({
                    message: error?.message,
                    bg: 'danger',
                });
                throw error;
            }
        },
        getNextPageParam: (_lastItem, allItems) => {
            // const lastItem = allItems.findLast((item) => item.length > 0);
            if (_lastItem.length) {
                const lastRecord = _lastItem[_lastItem.length - 1];
                if (lastRecord.dateInserted) return { ...baseDTO, fromDate: lastRecord.dateInserted } 
            }
            return undefined;
        },
        retry: true,
        staleTime: Infinity, // prevent refetching old data
        enabled: state.visible
    });
    
    const flatData = useMemo<INewsShort[] | undefined>(() => {
        return newsQuery.data?.pages.flat();
    }, [newsQuery.data]);

    // debounce as it otherwise gets called way too many times
    useDebounceEffect(() => {
        if (inView) {
            newsQuery.fetchNextPage();
            // if (isSearching) querySearch.fetchNextPage();
            // else query.fetchNextPage();
        }
    }, { delay: 100 }, [newsQuery.data, /*querySearch.data,*/ inView] )
    
    const isLoading = graphQuery.isLoading;

    const g = graphQuery.data;
    const [showMode, setShowMode] = useState<'nyheder'|'kurshistorik'>('nyheder');

    useEffect(() => {
        setHoverDate(undefined);
    }, [state.isin])

    const gSorted = useMemo(() => g?.data ? g.data.slice().reverse() : [], [g])

    const count = gSorted.length
    const virtualizer = useVirtualizer({
      count,
      getScrollElement: () => bodyRef.current,
      estimateSize: () => 38,
    })
  
    const items = virtualizer.getVirtualItems()
    
    const getStockPriceChangeForHeader = function() {
        if (range === GraphRange.DAY_1 && companyShareData?.shareprice) {
            return {
                value: companyShareData.shareprice.change,
                percent: companyShareData.shareprice.changePct,
                class:  getPriceChangeClass(companyShareData.shareprice.change)
            }
        }
        else if (g) {
            return {
                value: getChange(g.historicPrice.price, g.data[g.data.length - 1]?.price),
                percent: getPercentChange(g?.historicPrice?.price, g.data[g.data.length - 1]?.price),
                class:  getPriceChangeClass(g.data[g.data.length - 1]?.price - g.historicPrice.price)
            }
        }
        
        
    }();

    return (
        <Modal show={state.visible} size="lg">
            <Modal.Header>
                { 
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <h5 className="text-wrap me-3 fs-6 my-0">{g?.name ?? 'Loading'}</h5>
                        <div className="fs-6 text-end me-3">{g?.data ? parsePrice(g?.data[g.data.length - 1]?.price) : null}</div>
                        <div className={`fs-6 text-end me-3 ${getStockPriceChangeForHeader?.class}`}>
                            {g ? `${getStockPriceChangeForHeader?.value} (${getStockPriceChangeForHeader?.percent}%)` : ''}
                        </div>
                    </div>
                }
                <CloseButton onClick={state.close} />
            </Modal.Header>
            <Modal.Body>
                <div style={{height: '35vh'}}>
                    {(g?.data?.length && !isLoading) ?
                        (<ParentSize className="graph-container" debounceTime={10}>
                            {({ width: visWidth, height: visHeight }) => (
                                <StockChart
                                    width={visWidth}
                                    height={visHeight}
                                    series={[g]}
                                    colors={graphColors}
                                    showBottomChart={false}
                                    graphRange={range}
                                    highlightDate={hoverNewsDate} />
                            )}
                        </ParentSize>) :
                        ( !isLoading ?
                            <div className="d-flex justify-content-center h-100 align-items-center">Ingen graf data tilgængelig i valgte periode</div>
                        : null )
                    }
                </div>
                <div className="d-flex flex-row justify-content-center mt-1">
                    { graphRangeButtons.map((btn) => {
                        return (
                            <Button
                                size="sm"
                                key={btn.value}
                                onClick={() => handleClickGraphRangeButton(btn.value)}
                                className="rounded mx-1 d-flex justify-content-center"
                                style={{width: 25 + btn.text.length * 5}}
                                variant={getButtonVariant(btn.value)}>
                                <div>
                                    { btn.text }
                                </div>
                            </Button>
                        )
                    }) }
                </div>
                <div className="d-flex text-muted px-2 justify-content-between" style={{fontSize: 11, marginTop: 3}}>
                    <span>
                        Forsinket 15 min.
                    </span>
                </div>

                <div className={`row mt-3 ${isMobile ? 'flex-column-reverse' : ''}`}>
                    <div className="col-12 col-lg-6">
                        {
                            !isMobile ? 
                            <div className="row mx-1">
                                <Button className="w-auto me-2 mb-1" size="sm" variant={`${showMode == 'nyheder' ? '' : 'outline-'}primary`} onClick={() => setShowMode('nyheder')}>
                                    Nyheder
                                </Button>
                                <Button className="w-auto mb-1" size="sm" variant={`${showMode == 'kurshistorik' ? '' : 'outline-'}primary`} onClick={() => setShowMode('kurshistorik')}>
                                    Kurshistorik
                                </Button>
                            </div>
                        : null
                        }

                        <div className="px-1 overflow-auto w-100 rounded-bottom" ref={bodyRef} style={{height: '35vh'}}>
                            { showMode == 'nyheder' ?
                                <ListGroup
                                    variant="flush"
                                    as="ol"
                                    style={{cursor:'pointer'}}
                                    className="fs-7"
                                    onMouseLeave={() => setHoverDate(undefined)}
                                    >
                                    {
                                        renderedData(flatData)
                                    }
                                    {
                                        (newsQuery.hasNextPage) && <div className="text-center text-muted" ref={inViewRef}>Loading...</div>
                                    }
                                    {
                                        (!newsQuery.hasNextPage) && <div className="text-center text-muted" ref={inViewRef}>Ikke flere resultater</div>
                                    }
                                </ListGroup>
                                :
                                <div 
                                    style={{
                                    height: virtualizer.getTotalSize(),
                                    width: '100%',
                                    position: 'relative',
                                    cursor: 'pointer'
                                  }}
                                >
                                    <ListGroup
                                        variant="flush"
                                        as="ol"
                                        onMouseLeave={() => setHoverDate(undefined)}
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            transform: `translateY(${items[0]?.start || 1}px)`,
                                        }}>
                                    {
                                        items.map(x => 
                                            <li 
                                                key={x.index}
                                                data-index={x.index}
                                                onMouseEnter={() => setHoverDate(gSorted[x.index].date)}
                                                ref={virtualizer.measureElement}
                                                className="list-group-item list-group-item-action d-flex p-2 justify-content-between fs-7"
                                            >
                                                <span>
                                                    {parsePrice(gSorted[x.index].price)}
                                                </span>
                                                <span className="text-muted ms-1" style={{minWidth: 'max-content'}}>
                                                    {toLocalDateTimeStrRange(gSorted[x.index].date, range)}
                                                </span>
                                            </li>
                                        )
                                    }
                                    </ListGroup>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        { g != null && stockInfoQueryData != null ? 
                        <div className='d-flex flex-column justify-content-between me-1 mt-3 mt-lg-0'>
                            <div className={"w-100 px-3 py-1 rounded " + getGraphPriceChangeClass(changeToday?.change)}>
                                <span className="">Dagens ændring</span>
                                <h3>{companyShareData?.shareprice?.change}</h3>
                            </div>

                            <div className="d-flex w-100 mt-2 justify-content-between">
                                <div className="px-3 py-1 rounded bg-success-lite text-success" style={{width: '49%'}}>
                                    <span className="">Høj</span>
                                    <h5>{shortenPrice(highLowToday?.high)}</h5>
                                </div>
                                <div className="px-3 py-1 rounded bg-danger-lite text-danger" style={{width: '49%'}}>
                                    <span className="">Lav</span>
                                    <h5>{shortenPrice(highLowToday?.low)}</h5>
                                </div>
                            </div>

                            <div className="w-100 px-3 py-1 mt-2 rounded bg-light-2 text-muted">
                                <span className="">Volumen</span>
                                <h4>{parsePrice(stockInfoQueryData.volume)}</h4>
                            </div>

                            <div className="d-flex w-100 mt-2 justify-content-between">
                                <div className="px-3 py-1 rounded bg-light-2 text-muted" style={{width: '49%'}}>
                                    <span className="">Høj 52 uger</span>
                                    <h5>{shortenPrice(highLow52Weeks?.high)}</h5>
                                </div>
                                <div className="px-3 py-1 rounded bg-light-2 text-muted" style={{width: '49%'}}>
                                    <span className="">Lav 52 uger</span>
                                    <h5>{shortenPrice(highLow52Weeks?.low)}</h5>
                                </div>
                            </div>

                            <div className="w-100 px-3 py-1 mt-2 rounded bg-light-2 text-muted">
                                <span className="">Ændring ÅTD</span>
                                <h5>{changeYTD?.changePct}%</h5>
                            </div>
                            
                        </div> : null}
                    </div>
                </div>
            </Modal.Body>
            
        </Modal>
    );
}