import './scss/custom.scss'
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css'
import { MainContainer } from './components/layout/MainContainer';
import { ReaderWindow } from './components/reader/ReaderWindow';
import { Notifications } from './components/Notifications';
import { NavMenu } from './components/layout/NavMenu';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { EditModal } from './components/layout/EditModal';
import { StockWindow } from './components/StockWindow';
import { SettingsModal } from './components/settingsModal/SettingsModal';
import { useWebsocketSubscriber } from './hooks/useWebsocketSubscriber';
import { useInactivityHandler } from './hooks/useInactivityHandler';
import { useEffect, useState } from 'react';
import { useAuth } from 'bff-auth-lib';

//for mobile
import { isMobile } from 'react-device-detect'
import { MobileNavMenu } from './components/layout/mobile/MobileNavMenu';
import { MobileMainContainer } from './components/layout/mobile/MobileMainContainer';
import { Modal } from 'react-bootstrap';
import { useLayoutStore } from './stores/layout';

import logo from './assets/logo_name.svg';
import logo_white from './assets/logo_name_white.svg';
import { useThemeStore } from './stores/theme';
import SignalRConnector from './signalr-connection';
import * as signalR from "@microsoft/signalr";
const { connection, startConnection : startSignalRConnection } = SignalRConnector();

function App() {
  useWebsocketSubscriber();
  useInactivityHandler();

  const [showSettings, setShowSettings] = useState<boolean>(false);

  // temp
  const [showRedirectModal, setShowRedirectModal] = useState(false);
  const searchParams = new URLSearchParams(document.location.search);
  
  const searchParamSource = searchParams.get('source');
  useEffect(() => {
    if (searchParamSource == 'old') {
      setShowRedirectModal(true);
      window.history.pushState({}, "", window.location.pathname)
    }
  }, 
  [searchParamSource])

  // Ensure auth store is set up, so it can be loaded by layout.
  const auth = useAuth();
  const layoutStore = useLayoutStore();
  const themeStore = useThemeStore();
  useEffect(() => {
    if (auth.user && auth.loading == false) {
      layoutStore.initLayout(auth);
      if (connection.state == signalR.HubConnectionState.Disconnected) {
        console.log('connected. state: ' + connection.state);
        startSignalRConnection();
      }
    }
  }, [auth])

  if (layoutStore.columns.length == 0) {
    return  <div className="d-flex position-absolute bg-transparent h-100 w-100">
        <div className="d-flex spinner-border m-auto" role="status">
              <span className="visually-hidden">Loading...</span>
        </div>
    </div>
  }

  const redirectFromOldAppMsg = (
    <>
      <div>Velkommen til vores nye nyhedsapp til pc og mobil, som vi har valgt at kalde MarketWire Terminal. Vores gamle nyhedsapp lukkede den 1. oktober 2024. Husk at bogmærke siden eller gem den som favorit i din browser, så du undgår at blive omdirigeret næste gang.</div>
      <br />
      <div className="fst-italic">Har du spørgsmål eller brug for hjælp, er du meget velkommen til at kontakte os:</div>
      <br/>
      <div className="fw-bold">Thomas Müller</div>
      <div className="fst-italic">Sales Manager</div>
      <a className="fst-italic" href="mailto:tm@marketwire.dk">tm@marketwire.dk</a>
      <br/>
      <br/>
      <div className="fw-bold">Jacob Kristensen</div>
      <div className="fst-italic">Account Manager</div>
      <a className="fst-italic" href="mailto:jk@marketwire.dk">jk@marketwire.dk</a>
      <br/>
    </>
  )

  return (
      <div className="App">
        <Modal show={showRedirectModal} onHide={() => setShowRedirectModal(false)}>
          <Modal.Header closeButton>
          <img
            src={themeStore.theme === 'dark' ? logo_white : logo}
            height="25"
            className="d-inline-block align-top"
            alt="Logo"
          />
          </Modal.Header>
          <Modal.Body>
            { redirectFromOldAppMsg }
          </Modal.Body>
        </Modal>
        <div className="overflow-hidden d-flex flex-column bg-light">
          { isMobile ? 
          <>
            <MobileNavMenu showSettingsModal={() => setShowSettings(true)}/>
            <MobileMainContainer />
          </> :
          <>
            <NavMenu showSettingsModal={() => setShowSettings(true)} />
            <MainContainer />
          </>}
          <EditModal />
          <ReaderWindow />
          <StockWindow />
        </div>
        <Notifications />
        <SettingsModal visible={showSettings} close={() => setShowSettings(false)}  />
      </div>
  )
}

export default App
