//https://www.abrahamberg.com/blog/aspnet-signalr-and-react/

import * as signalR from "@microsoft/signalr";
const URL = import.meta.env.VITE_SIGNALR_URL;

/** signalR connection singleton */
class Connector {
    static instance: Connector;
    /** set to true when the websocket has been closed for timeBeforeStale*/
    static stale: boolean;

    public connection: signalR.HubConnection;

    constructor() {
        Connector.stale = false;
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(URL)
            .withAutomaticReconnect([0, 2000, 5000, 10_000, 30_000, 60_000])
            .configureLogging(signalR.LogLevel.Trace)
            .build();

        this.connection.onclose(error => {
            console.log('signalR connection closed');
            if (error) {
                console.error(error);
            }
            else {
                console.log("No error obj provided");
            }
            
            Connector.stale = true;
        });

        this.connection.onreconnecting((error) => {
            console.log('signalR onreconnecting failed')
            if (error) {
                console.error(error);
            }
            else {
                console.log("No error obj provided");
            }
            
        });

        this.connection.onreconnected(() => {
            console.log('signalR connection reconnected, stale=' + Connector.stale)
        })
    }
    /** moved from constructor so we can call this only after getting auth cookies */
    public async startConnection() {
        Connector.instance.connection.start()
            .then(() => {
                console.log('signalR connection connected, stale=' + Connector.stale)
                console.log('connectionId: ' + Connector.instance.connection.connectionId);
            })
            .catch(error => {
                console.log('signalR connection failed to start' + error?.message)
                console.error(error);
                if (Connector.instance.connection.state !== signalR.HubConnectionState.Reconnecting) {
                    Connector.stale = true;
                }
            });
    }
    public setStale() {
        Connector.stale = true;
        console.log('set stale to true');
    }
    /** returns true if reconnected, otherwise false */
    public async reconnectIfStale():Promise<boolean> {
        if (Connector.stale && Connector.instance.connection.state == signalR.HubConnectionState.Disconnected) {
            try {
                console.log('trying to reconnect because stale')
                await Connector.instance.startConnection();
                Connector.stale = false;
                return true;
            } catch (error) {
                console.log('failed to reconnect stale signalR connection with error: ');
                console.error(error);
            }
        }
        else {
            console.log("not reconnecting because state: " + Connector.instance.connection.state)
            Connector.stale = false;
        }
        return false;
    }

    /** gets the connection singleton */
    public static getInstance(): Connector {
        if (!Connector.instance) {
            Connector.instance = new Connector();
            console.log('created connector instance singleton')
        }
            
        return Connector.instance;
    }

}
export default Connector.getInstance;