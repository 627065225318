import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { Badge, Card, CloseButton, ListGroup } from 'react-bootstrap';
import NewsService, { IMediaItem, INewsDetail } from '../../services/newsService';
import { toLocalDateTimeFullStr, toLocalDateTimeStr } from '../../helpers/dateHelper';
import { useNotifyStore } from '../../stores/notify';
import RawHtmlComponent from './RawHtmlComponent';
import { getSharePriceChangeClass, parsePrice } from '../../helpers/shareHelper';
import HighlightText from './HighlightText';
import { DangerousHTMLWithScripts } from '../shared/DangerousHTMLWithScripts';


type Props = {
    newsId: string,
    searchStr:string,
    close: () => void,
}
export const NewsfeedRW = (props:Props) => {
    const notifyCreate = useNotifyStore(state => state.create)
    const query = useQuery<INewsDetail>({
        queryKey: ['newsDetail', props.newsId],
        queryFn: async () => { 
            try {
                return await NewsService.GetDetail(props.newsId) 
            } catch (error:any) {
                notifyCreate({
                    message: error?.message, //'Get news detail request failed due to a network error.'
                    bg: 'danger',
                });
                throw error;
            }
            
        },
        staleTime: 5 * 60_000,
    })
    const data = query.data;

    const mediaItemElm = (item:IMediaItem) => {
        return (
            <div className="mb-3" key={item.id}>
                <img className="w-100" src={`https://picture.ritzau.dk/ritzau/robpic/${item.filenameOrg}_1000.jpg`} alt=""></img>
                <div className="text-muted fs-8">
                    <span>{item.subtitle}. Foto:</span>
                    <span className="ms-1 fw-bold text-nowrap fst-italic">{item.photoCredit}</span>
                </div>
            </div>
        )
    }

    /** extracts anchor href from raw html and converts it to an anchor with target="_blank" attribute */
    const LinkEmbed = ({rawHtml}:{rawHtml:string}) => {
        const parser = new DOMParser();
        const parsedHtml = parser.parseFromString(rawHtml, 'text/html');
        const anchors = parsedHtml.getElementsByTagName('a');
        if (anchors.length == 0) return null;
        const anchor = anchors[0];
        
        return <a href={anchor.href} target="_blank">{anchor.textContent}</a>
    }

    return (
        !data || query.isLoading ? 
        <>
            <Card.Header className="border-0">
                <CloseButton className="float-end" onClick={props.close}></CloseButton>
            </Card.Header>
            <div className="spinner-border m-auto" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
        </>
        :
        <>
            
            <div className="mx-3 my-2">
                <small className="text-muted">
                    <span className="me-4">
                        {toLocalDateTimeFullStr(data.dateInserted)}
                    </span>
                    {/* <u className="me-4">
                        Print <i className="bi bi-printer-fill ms-1"></i>
                    </u>
                    <u className="me-4">
                        Del <i className="bi bi-send-fill ms-1"></i>
                    </u> */}
                </small>
                <CloseButton className="float-end" onClick={props.close}></CloseButton>
            </div>
            
            <div className="mx-3 my-1 border-0">
                <h5><HighlightText text={data.finansHeadline} searchString={props.searchStr} /></h5>
            </div>
            
            <div className="card-body rawhtml-container">
            {
                data.mediaItems?.length ? (
                    data.mediaItems.map(x => mediaItemElm(x))
                ) : null
            }
            {
                data.content && <RawHtmlComponent rawHtml={data.content} searchString={props.searchStr} />
            }
            {
                data.embeds?.map((x, i) => 
                    x.embedTypeId == 1 ? <LinkEmbed rawHtml={x.embedCode} key={i} />
                    : <DangerousHTMLWithScripts key={i} html={x.embedCode} />
                
                    
                )
            }
           
            { data.companies && data.companies.filter(x => x.shareprice).length > 0 &&
            <>
                <ListGroup as="ol" className="mb-3">
                {data.companies.map((item, index) => {
                    return (
                        <li key={index}
                            className="list-group-item list-group-item-action d-flex justify-content-between align-items-center p-2 fs-7"
                        >
                            <span>
                                {item.name}
                            </span>
                            <span className="ms-1 d-flex justify-content-between align-self-end flex-col-reverse fs-6" style={{minWidth: 'max-content'}}>
                                <span className="d-flex justify-content-end">
                                    <Badge  pill bg="transparent" className="me-2 text-dark">
                                        {parsePrice(item.shareprice?.price)}
                                    </Badge>
                                </span>
                                <span className="d-flex justify-content-end" style={{minWidth: 57, textAlign: 'right'}}> {/** todo: fjern baggrundsfarve */}
                                    <Badge pill bg="transparent" className={getSharePriceChangeClass(item)}>
                                    {parsePrice(item.shareprice?.changePct)}%
                                    </Badge>
                                </span>
                                <span className="d-flex justify-content-end" style={{minWidth: 70, textAlign: 'right'}}> {/** todo: fjern kommatal hvis abs val er > 100 */}
                                    <Badge pill bg="transparent" className={getSharePriceChangeClass(item)}>
                                        {parsePrice(item.shareprice?.change)}
                                    </Badge>
                                </span>
                            </span>
                        </li>
                        )})
                }
                </ListGroup>
            </>
                
            }
            <hr />
                <div className="my-2">
                    <small className="text-muted d-flex justify-content-between flex-wrap">
                        {
                            data.contactName &&
                            <div className="d-flex me-4">
                                {data.contactName}
                            </div>
                        }
    
                        {
                            data.contactPhone &&
                            <div className={`d-flex ps-2 ${data.contactName ? 'ms-auto' : null}`}>
                                <a style={{color: 'var(--bs-primary)'}} href={`tel:${data.contactPhone}`}>{data.contactPhone}</a> <i className="bi bi-phone-fill ms-1" style={{color: 'var(--bs-primary)'}}></i>
                            </div>
                        }
                        
                        {
                            data.contactEmail &&
                            <div className="d-flex ms-auto ps-2">
                                <a style={{color: 'var(--bs-primary)'}} href={`mailto:${data.contactEmail}`}>{data.contactEmail}</a> <i className="bi bi-send-fill ms-1" style={{color: 'var(--bs-primary)'}}></i>
                            </div>
                        }
                        
                    </small>
                </div>
            </div>

        </>
  );
}
