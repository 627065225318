/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useRef, useState, useMemo, useCallback, createRef, useEffect } from 'react';
import { scaleTime, scaleLinear, scaleBand } from '@visx/scale';
import { Brush } from '@visx/brush';
import { Bounds } from '@visx/brush/lib/types';
import BaseBrush, { BaseBrushState, UpdateBrush } from '@visx/brush/lib/BaseBrush';
import { PatternLines } from '@visx/pattern';
import { Group } from '@visx/group';
import { LinearGradient } from '@visx/gradient';
import { max, extent, bisector, min, ascending } from 'd3-array';
import { BrushHandleRenderProps } from '@visx/brush/lib/BrushHandle';
import { AxisLeft, AxisRight, AxisBottom, AxisScale } from '@visx/axis';
import { LinePath, Line } from '@visx/shape';
import AreaChart from './AreaChart';
import { useTooltip, TooltipWithBounds, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { GlyphCircle } from '@visx/glyph';
import { GraphData, GraphPoint, GraphRange } from '../../services/shareService';
import { axisBottomDateFormatter, graphTooltipDateStr } from '../../helpers/dateHelper'
import { ScaleBand } from 'd3-scale';
import { nice } from 'd3-array';
import { getPercentChangeNum, getPriceChangeClass, parsePrice, shortenPrice } from '../../helpers/shareHelper';
import { GridRows, GridColumns, Grid } from '@visx/grid';
// Initialize some variables
const brushMargin = { top: 10, bottom: 15, left: 20, right: 40 };
const chartSeparation = 30;
const PATTERN_ID = 'brush_pattern';
const GRADIENT_ID = 'brush_gradient';
export const accentColor = 'var(--bs-white)';
export const background2 = 'var(--bs-dark)';
const gridLineColor = 'var(--bs-dark)'
const gridLineOpacity = 0.1;
const axisColor = 'var(--bs-axis-color)';

const tooltipStyles = {
  ...defaultStyles,
  minWidth: 45,
  margin: 15,
  backgroundColor: 'var(--bs-light)',
  color: 'var(--bs-black)',
  fontSize: 11,
};

const selectedBrushStyle = {
  fill: `url(#${PATTERN_ID})`,
  stroke: 'var(--bs-light)',
};


const axisBottomTickLabelProps = {
  textAnchor: 'middle' as const,
  fontFamily: 'Arial',
  fontSize: 10,
  fill: axisColor,
};



// accessors
const getDate = (d: GraphPoint) => new Date(d.date);
const getStockValue = (d: GraphPoint) => d.price;
const bisectDate = bisector<GraphPoint, Date>((d:GraphPoint) => new Date(d.date)).left;
const bisectDateCenter = bisector<GraphPoint, Date>((d:GraphPoint) => new Date(d.date)).center;
/** since bandScale doesn't have an .invert function 
 * source: https://stackoverflow.com/questions/38633082/d3-getting-invert-value-of-band-scales
 * it may not work for zooming, don't know if this will be relevant. there is maybe a solution for this in the thread
*/
function dateScaleBandInvert(scale:ScaleBand<Date>) {
  var domain = scale.domain();
  var paddingOuter = scale(domain[0]);
  var eachBand = scale.step();
  return function (value:number) {
    var index = Math.floor(((value - (paddingOuter ?? 0)) / eachBand));
    return domain[Math.max(0,Math.min(index, domain.length-1))];
  }
}

type Props = {
    series: GraphData[], // series is the array of different data that we want to show
    colors: string[],
    width: number;
    height: number;
    margin?: { top: number; right: number; bottom: number; left: number };
    compact?: boolean;
    showBottomChart?: boolean;
    graphRange: GraphRange,
    highlightDate?: string,
};

export default function StockChart({
      series = [],
      graphRange,
      colors,
      showBottomChart = false,
      compact = false,
      width,
      height,
      margin = {
        top: 10,
        left: 25,
        bottom: 10,
        right: 25,
      },
      highlightDate,
    }: Props)
{
  const [filteredSeries, setFilteredSeries] = useState<GraphData[]>(series);
  const isFirstRender = useRef(true);
  React.useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    setFilteredSeries(series);
  }, [series])
  
  const flatFilteredSeriesData = useMemo<GraphPoint[]>(
    () =>  {
      return [... new Set(
        filteredSeries.flatMap(x => x.data).sort((a, b) => ascending(a.date, b.date))
        )
      ]
    },
    [filteredSeries]
  );
  
  const brushRef = useRef<BaseBrush | null>(null);
  const onBrushChange = (domain: Bounds | null) => {
    if (!domain) return;
    const { x0, x1, y0, y1 } = domain;
    const res:GraphData[] = [];
    for (let i = 0; i < series.length; i++) {
      const dataCopy = series[i].data.filter((s) => {
        const x = getDate(s).getTime();
        // changed so we don't require y axis to fit the bottom brushgraph's 'domain'in order to show
        // since bottom graph may have different y axis than than some of the graphs in the series.

        // const y = getStockValue(s);
        return x > x0 && x < x1;
        // return x > x0 && x < x1 && y > y0 && y < y1;
      });
      const copy = { ... series[i], data: dataCopy };
      res.push(copy);
    }
    
    setFilteredSeries(res)
  };


  const axisRightTickLabelProps = {
    fontFamily: 'Arial',
    fontSize: 10,
    textAnchor: 'start' as const,
    fill: series.length == 2 ? colors[0] : axisColor,
  };

  const axisLeftTickLabelProps = {
    fontFamily: 'Arial',
    fontSize: 10,
    textAnchor: 'end' as const,
    fill: series.length == 2 ? colors[1] : axisColor,
  };
  const axisBottomTicks = width > 520 ? 7 : 5

  function getPriceCharCount (data:GraphPoint[]) {
    const valueArr = data.map(x => getStockValue(x));
    const minPrice = min(valueArr)
    const maxPrice = max(valueArr);
    const madeNice = nice(minPrice ?? 0, maxPrice ?? 0, 2);
    const parsed = madeNice[madeNice.length-1].toString();
    return parsed.length;
  }
  const charPixelsMultiplier = 4;

  const fMargin = {
    top: margin.top,
    bottom: margin.bottom,
    left: margin.left,
    right: margin.right
  };
  
  if (filteredSeries.length <= 2) {
    fMargin.right = margin.right + getPriceCharCount(filteredSeries[0].data) * charPixelsMultiplier;
    if (filteredSeries.length == 2) {
      fMargin.left = margin.left + getPriceCharCount(filteredSeries[1].data) * charPixelsMultiplier;
    }
  }

  const innerHeight = height - fMargin.top - fMargin.bottom;
  const topChartBottomMargin = showBottomChart ? (compact ? chartSeparation / 2 : chartSeparation + 10) : 0;
  const topChartHeight = (showBottomChart ? 0.8 : 0.9) * innerHeight - topChartBottomMargin;
  const bottomChartHeight = innerHeight - topChartHeight - chartSeparation;

  // bounds
  const xMax = Math.max(width - fMargin.left - fMargin.right, 0);
  const yMax = Math.max(topChartHeight, 0);
  const xBrushMax = Math.max(width - brushMargin.left - brushMargin.right, 0);
  const yBrushMax = Math.max(bottomChartHeight - brushMargin.top - brushMargin.bottom, 0);

  const dateBandScaleFull = useMemo(() => {
    return scaleBand<Date>({
      range: [0, xMax],
      domain: flatFilteredSeriesData.map(x => getDate(x)),
      paddingInner: 1 // https://observablehq.com/@d3/d3-scaleband
  })},
  [xMax, filteredSeries]);


  // const dateScaleFull = useMemo(() => {
  //   return scaleTime<number>({
  //     range: [0, xMax],
  //     domain: extent(flatFilteredSeriesData, getDate) as [Date, Date],


  //   })}, [xMax, filteredSeries],
  // );

  // used for tooltips
  // const stockValueScale = React.useMemo(
  //   () =>
  //     scaleLinear({
  //       range: [innerHeight + fMargin.top, fMargin.top],
  //       domain: [0, (max(flatFilteredSeriesData, getStockValue) || 0) + innerHeight / 3],
  //       nice: true,
  //     }),
  //   [fMargin.top, innerHeight, filteredSeries],
  // );

  const getMaxStockValue = (gd:GraphData) => {
    return max(gd.data, getStockValue) ?? 0;
  }
  const getMinStockValue = (gd:GraphData) => {
    return min(gd.data, getStockValue) ?? 0;
  }

  /** Creates a ScaleLinear array to fit every provided data set in the series prop (Y axis) */
  const stockScaleArray = useMemo(() => {
    const arr = [];
    for (let i = 0; i < filteredSeries.length; i++) {
      const dataSet = filteredSeries[i];
      arr.push(
        scaleLinear<number>({
          range: [yMax, 0],
          domain: [getMinStockValue(dataSet), getMaxStockValue(dataSet)],
          nice: true,
        })
      );
    }
    return arr;
  }, [yMax, filteredSeries]);

    //determine whether to show axis:
    const hideBottomAxis = compact;
    const hideRightAxis = stockScaleArray.length > 2;
    const hideLeftAxis = stockScaleArray.length !== 2;

  /** Lower graph (brush) uses the first data array in the series array prop
   */
  const brushDateScale = useMemo(
    () =>
      scaleTime<number>({
        range: [0, xBrushMax],
        domain: extent(flatFilteredSeriesData, getDate) as [Date, Date],
      }),
    [xBrushMax, filteredSeries],
  );
  const brushStockScale = useMemo(
    () =>
      scaleLinear({
        range: [yBrushMax, 0],
        domain: [0, getMaxStockValue(series[0])],
        nice: true,
      }),
    [yBrushMax, filteredSeries],
  );

  const initialBrushPosition = useMemo(
    () => ({
      start: { x: brushDateScale(getDate(flatFilteredSeriesData[0])) },
      end: { x: brushDateScale(getDate(flatFilteredSeriesData[flatFilteredSeriesData.length - 1])) }, // todo: this might be causing errors
    }),
    [brushDateScale, filteredSeries],
  );

  // event handlers
  const handleClearClick = () => {
    if (brushRef?.current) {
      setFilteredSeries(series);
      brushRef.current.reset();
    }
  };

  const handleResetClick = () => {
    if (brushRef?.current) {
      const updater: UpdateBrush = (prevBrush) => {
        const newExtent = brushRef.current!.getExtent(
          initialBrushPosition.start,
          initialBrushPosition.end,
        );

        const newState: BaseBrushState = {
          ...prevBrush,
          start: { y: newExtent.y0, x: newExtent.x0 },
          end: { y: newExtent.y1, x: newExtent.x1 },
          extent: newExtent,
        };

        return newState;
      };
      brushRef.current.updateBrush(updater);
    }
  };

  /** Zoom in and out the graph with scroll wheel */
  function onScrollWheel(event:React.WheelEvent<HTMLDivElement>) {
    if (brushRef?.current) {
        const currWidth = brushRef.current.getBrushWidth();
        const xBrushMin = brushMargin.left;
        const change = (event.deltaY > 0 ? 1 : -1) / (currWidth < 4 ? 3 : 1)
        const newWidth = currWidth + (change); // change multiplied by two previously
        const brushCorners = brushRef.current.corners();
        if (!brushCorners?.bottomLeft) return;
        if (newWidth > 0 && (brushCorners.bottomLeft.x - change) >= -4.0)
        {
            const updater: UpdateBrush = (prevBrush) => {
                const newExtent = brushRef.current!.getExtent(
                  { y: prevBrush.start.y, x: prevBrush.start.x - change},
                  { y: prevBrush.end.y, x: prevBrush.end.x },
                );
        
                const newState: BaseBrushState = {
                  ...prevBrush,
                  start: { y: newExtent.y0, x: newExtent.x0 },
                  end: { y: newExtent.y1, x: newExtent.x1  },
                  extent: newExtent,
                };
        
                return newState;
              };
              brushRef.current.updateBrush(updater);
        }
    }
  }

    /** Gets a reference to each of the graph line DOM elements. */
    const lineRefs = useRef(series.map(() => React.createRef<SVGPathElement>()))

  interface GraphTooltip {
    graphData: GraphData,
    point: GraphPoint,
    dayChange?: number,
    textColor: string,
    snapX: number,
  }
  // tooltip parameters
  const { tooltipData, tooltipLeft = 0, tooltipTop = 0, showTooltip, hideTooltip } = useTooltip<(GraphTooltip|undefined)[]>();
  

  // to make jumping between points in the graph more centered
  const cursorOffsetPixels = 330 / (flatFilteredSeriesData.length ?? 1); //prevent div by 0

  // tooltip handler
  const handleTooltip = useCallback(
    (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
      const { x, y } = localPoint(event) || { x: 0 };
      // We add +cursorOffsetPixels to the x position so that its possible to hover the NEWEST index, and makes it jump better between the data points.
      const x0 = dateScaleBandInvert(dateBandScaleFull)(x - fMargin.left + cursorOffsetPixels); 
      const graphTooltips:(GraphTooltip|undefined)[] = []

      for (let i = 0; i < filteredSeries.length; i++) {
        const iRef = lineRefs.current[i]?.current;
        if (iRef) {
          const rect = iRef.getBoundingClientRect();
          const clientX = (event as React.MouseEvent<SVGRectElement>).clientX
            || (event as React.TouchEvent).touches[0]?.clientX

          if (!clientX || (clientX - 5 > rect.right || clientX < rect.left)) { // added -5 pixels to give cursor a bit of leeway at last graph point
            graphTooltips.push(undefined);
            continue;
          }
        }
        const fData = filteredSeries[i].data;
        const fDataIndex = bisectDate(fData, x0, 1)
      
        const d0 = fData[fDataIndex - 1];
        const d1 = fData[fDataIndex]
        let d = d0;
        if (d1 && getDate(d1)) {
          d = x0.valueOf() - getDate(d0).valueOf() > getDate(d1).valueOf() - x0.valueOf() ? d1 : d0;
        }
        const previousDate = new Date(d.date);
        const currentDay = previousDate.getDay();

        if (currentDay === 1) //monday
        {
          previousDate.setDate(previousDate.getDate() - 3);
        }
        else {
          previousDate.setDate(previousDate.getDate() - 1);
        }
        let prevDateIndex = bisectDate(fData, previousDate, 0);
        // there may be a holiday yesterday, or the day before, which means we'll get the same date as today
        // bisect the day before until we get another index, or 0
        while (prevDateIndex === fDataIndex && prevDateIndex != 0) {
          previousDate.setDate(previousDate.getDate() - 1);
          prevDateIndex = bisectDate(fData, previousDate, 0);
        }

        const dateX = dateBandScaleFull(getDate(d));
        const gtt:GraphTooltip = {
          graphData: series[i],
          point: d,
          textColor: colors[i],
          snapX: dateX ?? 0
        }

        if (prevDateIndex !== fDataIndex) {
          const prevDatePrice = fData[prevDateIndex].price;
          //const change = Math.abs(d.price - yesterdayPrice).toFixed(4);
          const changePct = getPercentChangeNum(prevDatePrice, d.price);
          gtt.dayChange = changePct;
        }

        graphTooltips.push(gtt);
      }
      showTooltip({
          tooltipData: graphTooltips,
          tooltipLeft: x,
          tooltipTop: y,

      });
    },
    [showTooltip, dateBandScaleFull],
  );

  const minDate = new Date(flatFilteredSeriesData[0]?.date)
  const maxDate = new Date(flatFilteredSeriesData[flatFilteredSeriesData.length - 1].date);
  
  const highlightDateX = () => {
    if (highlightDate && series.length) {
      const highlightDateConverted = new Date(highlightDate)
      const highlightIndex = bisectDateCenter(series[0].data, highlightDateConverted);
      // ignore if nearest date is the oldest point in the data
      const xDate = series[0].data[highlightIndex].date;
      if (highlightIndex == 0 && xDate !== highlightDate) {
        return undefined;
      }

      
      const xDateConverted = new Date(xDate);
      const xCoord = dateBandScaleFull(xDateConverted);
      if (xCoord) {
        return highlightIndex === 0 ? xCoord + 1 : highlightIndex === series[0].data.length - 1 ? xCoord - 1 : xCoord;
      }
      
    }
  }
  const highlightedDateX = highlightDateX();
  
  
  return (
    <div onWheel={onScrollWheel} style={{position: 'relative'}} className="bg-white">
      <svg width={width} height={height}>
        <Group left={fMargin.left} top={fMargin.top}>
          <Grid
            xScale={dateBandScaleFull}
            yScale={stockScaleArray[0]}
            width={xMax}
            height={yMax}
            numTicksRows={5}
            numTicksColumns={axisBottomTicks}
            stroke={gridLineColor}
            strokeOpacity={0.1}
          />
          {/* Main chart */}
          { filteredSeries.map((fGraph, i) => (
              <LinePath
                innerRef={lineRefs.current[i]}
                key={i}
                stroke={colors[i]}
                strokeWidth={1}
                data={fGraph.data}
                x={(d) => dateBandScaleFull(getDate(d)) ?? 0}
                y={(d) => stockScaleArray[i](getStockValue(d)) ?? 0}
              />
            ))
          }
          { /* Highlight Date (used for StockWindow) */ }
          {highlightedDateX ? (
            <g>
                <Line
                    from={{ x: highlightedDateX, y: 0 }}
                    to={{ x: highlightedDateX, y: yMax }}
                    stroke={'var(--bs-dark)'}
                    strokeWidth={1}
                    pointerEvents="none"
                    strokeDasharray="4,1"
                />
            </g>
          ) : null}
          {/* Tooltip */}
          {tooltipData?.length ? (
              <g>
                  <Line
                      from={{ x: tooltipLeft - fMargin.left, y: 0 }}
                      to={{ x: tooltipLeft - fMargin.left, y: yMax }}
                      stroke={'var(--bs-graph-line)'}
                      strokeWidth={1}
                      pointerEvents="none"
                      strokeDasharray="4,1"
                  />
              </g>
          ) : null}
          {tooltipData?.length ? tooltipData.map((d, i:number) => d && (
              <g key={i}>
                <GlyphCircle 
                  left={d.snapX}
                  top={stockScaleArray[i](d.point.price)}
                  size={40}
                  fill={d.textColor}
                  stroke={'black'}
                  strokeWidth={1} />
              </g>
          )) : null}
          <rect x={0} y={0} width={xMax} height={yMax} fill={'transparent'}
              onTouchStart={handleTooltip}
              onTouchMove={handleTooltip}
              onMouseMove={handleTooltip}
              onMouseLeave={() => hideTooltip()}
          />
  
          {/* Axis */}
          {!hideBottomAxis && (
            <AxisBottom
              top={yMax}
              scale={dateBandScaleFull}
              numTicks={axisBottomTicks}
              stroke={axisColor}
              tickStroke={axisColor}
              tickLabelProps={axisBottomTickLabelProps}
              tickFormat={(date:any) => axisBottomDateFormatter(date, minDate, maxDate)}
            />
          )}
          {!hideRightAxis && (
            <AxisRight
              scale={stockScaleArray[0]}
              left={width - fMargin.left - fMargin.right}
              numTicks={5}
              stroke={axisColor}
              tickStroke={axisColor}
              tickLabelProps={axisRightTickLabelProps}
              tickFormat={(val:any) => parsePrice(val)}
            />
          )}
          {!hideLeftAxis && (
            <AxisLeft
              scale={stockScaleArray[1]}
              numTicks={5}
              stroke={axisColor}
              tickStroke={axisColor}
              tickLabelProps={axisLeftTickLabelProps}
              tickFormat={(val:any) => parsePrice(val)}
            />
          )}
        </Group>
        {/* Bottom Volume Area chart */
        showBottomChart && 
        (
            <AreaChart
              hideBottomAxis
              hideLeftAxis
              data={series[0].data}
              width={width}
              yMax={yBrushMax}
              xScale={brushDateScale}
              yScale={brushStockScale}
              margin={brushMargin}
              top={topChartHeight + topChartBottomMargin + fMargin.top}
              gradientColor={background2}
            >
              <PatternLines
                id={PATTERN_ID}
                height={8}
                width={8}
                stroke={accentColor}
                strokeWidth={1}
                orientation={['diagonal']}
              />
              <Brush
                xScale={brushDateScale}
                yScale={brushStockScale}
                width={xBrushMax}
                height={yBrushMax}
                margin={brushMargin}
                handleSize={8}
                innerRef={brushRef}
                resizeTriggerAreas={['left', 'right']}
                brushDirection="horizontal"
                initialBrushPosition={initialBrushPosition}
                onChange={onBrushChange}
                onClick={() => setFilteredSeries(series)}
                selectedBoxStyle={selectedBrushStyle}
                useWindowMoveEvents
                renderBrushHandle={(props) => <BrushHandle {...props} />}
              />
            </AreaChart>
        )}
      </svg>
      {tooltipData?.some(x => x) ? (
        <TooltipWithBounds
          key={Math.random()}
          style={tooltipStyles}
          top={tooltipTop}
          left={tooltipLeft - fMargin.left}
        >
          {
            tooltipData?.length ?
              <div>
                {graphTooltipDateStr(tooltipData.find(x => x?.point.date)?.point.date ?? '', graphRange)}
              </div>
              : null
          }
          {tooltipData.map((td, i) => td && (
            <div key={i}>
              <div style={{fontWeight: 'bold'}}>
                {td.graphData.name}
              </div>
              <div>
                Kurs: {parsePrice(td.point.price)}
              </div>
              <div>
                Ændring: <span className={getPriceChangeClass(td.dayChange)}>{td.dayChange?.toFixed(2).replace('.', ',') ?? 0}%</span>
              </div>
              {/* <div>
                Volumen: {td.point.volume}
              </div> */}
              { (tooltipData.length - 1 > i) ? <hr style={{margin: 1}}/> : null }
            </div>
          ))}
    
        </TooltipWithBounds>
      )  : null}
      {/* <button onClick={handleClearClick}>Clear</button>&nbsp;
      <button onClick={handleResetClick}>Reset</button> */}
    </div>
  );
}

// We need to manually offset the handles for them to be rendered at the right position
function BrushHandle({ x, height, isBrushActive }: BrushHandleRenderProps) {
  const pathWidth = 8;
  const pathHeight = 15;
  if (!isBrushActive) {
    return null;
  }
  return (
    <Group left={x + pathWidth / 2} top={(height - pathHeight) / 2}>
      <path
        fill="#f2f2f2"
        d="M -4.5 0.5 L 3.5 0.5 L 3.5 15.5 L -4.5 15.5 L -4.5 0.5 M -1.5 4 L -1.5 12 M 0.5 4 L 0.5 12"
        stroke="#999999"
        strokeWidth="1"
        style={{ cursor: 'ew-resize' }}
      />
    </Group>
  );
}