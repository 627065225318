import { useState, useEffect, useMemo } from 'react';
import SignalRConnector from '../signalr-connection';
import { InfiniteData, QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import MyCompaniesService, { MyCompanyDTO } from '../services/myCompaniesService';
import { NewsShortWebSocket, PriceUpdateWebSocket } from '../types/websocketMessages';
import { INewsShort } from '../services/calendarService';
import { NewsfeedQueryKey, NewNewsfeedItems } from '../components/items/Newsfeed';
import { produce } from 'immer';
import { WatchMedierFeeds, WatchmedieNews } from '../services/watchmedieService';
import { GraphData, GraphRange, ICompany } from '../services/shareService';
import { useStockWindowStore } from '../stores/stockwindow';
import { GraphQueryKey } from '../components/items/Graph';
import { WatchMedierfeedItems } from '../components/items/Watchmedierfeed';
const { connection } = SignalRConnector();
/** React hook to subscribe to websocket updates and apply them to queryClient cache. */
export function useWebsocketSubscriber() {
    
    const queryClient = useQueryClient();

    const companyList = useQuery<MyCompanyDTO[]>({
        queryKey: ["myCompanyList"],
        queryFn: async () => await MyCompaniesService.GetList(),
        staleTime: Infinity,
        cacheTime: Infinity,
      });
    const companyListIds = companyList.data?.map(x => x.companyId) || [];

    useEffect(() => {
      connection.on("MissingIdentity", onMissingIdentity);
      connection.on("pushNews", (message:NewsShortWebSocket) => changeNewsCache(message, queryClient, companyListIds));
      connection.on("pushWatchMedieNews", (message:WatchmedieNews) => changeWatchMedierCache(message, queryClient));
      connection.on("resetStockChart", (placeholder:boolean) => resetStockChart(queryClient));
      connection.on("pushShareGraphUpdate", (messageArr:PriceUpdateWebSocket[]) => changeGraphCache(messageArr, queryClient));
      connection.on("pushShareUpdate", (messageArr:ICompany[]) => changeStockfeedCache(messageArr, queryClient));
      return () => {
        connection.off("MissingIdentity");
        connection.off("pushNews");
        connection.off("pushWatchMedieNews");
        connection.off("resetStockChart");
        connection.off("pushShareGraphUpdate");
        connection.off("pushShareUpdate");
      }
    }, [companyList.data])
}

const onMissingIdentity = () => {
  location.reload();
}

const changeNewsCache = (message:NewsShortWebSocket, queryClient:QueryClient, companyListIds:number[]) => {
  NewNewsfeedItems.value.add(message.data.id);
  queryClient.setQueriesData<InfiniteData<INewsShort[]>>(
    {
      predicate: function ({ queryKey }) {
        if (queryKey[0] === 'newsList') {
          const qk = queryKey[1] as NewsfeedQueryKey;
          if (qk.filterFlags & message.newsFilterFlags //flags comparison with bitwise AND
            && (qk.myStocks == false || message.companyIds.filter(x => companyListIds.includes(x)).length)
          ) {
            return true;
          }
        }
        return false
      }
    },
    (oldData:InfiniteData<INewsShort[]>|undefined) => {
      return (oldData && oldData.pages.length > 0) 
      ? produce(oldData, draft => {
        draft.pages[0].unshift(message.data);
      })
      : oldData
    },
  );
}

const changeWatchMedierCache = (message:WatchmedieNews, queryClient:QueryClient) => {
  WatchMedierfeedItems.value.add(message.id);
    queryClient.setQueriesData<InfiniteData<WatchmedieNews[]>>(
      {
        predicate: function ({ queryKey }) {
          if (queryKey[0] === 'watchMedieNews' && (queryKey[1] as WatchMedierFeeds[]).includes(message.feedEnum))
          { 
            return true
          }
          return false
        }
      },
      (oldData:InfiniteData<WatchmedieNews[]>|undefined) => {
        return (oldData && oldData.pages.length > 0) 
        ? produce(oldData, draft => {
          const itemToAddDate = new Date(message.datePublished);
          const allItems = oldData.pages
                  .flatMap((innerArray, pageIndex) => innerArray.map((value, innerIndex) => ({ pageIndex, innerIndex, ...value })));
  
          const insertIndex = allItems.findIndex(x => compareKronologisk(new Date(x.datePublished), itemToAddDate) <= 0);
  
          if (insertIndex == -1) {
            return;
          }
          else if (insertIndex == allItems.length - 1) {
            // add to bottom
            draft.pages[draft.pages.length-1].push(message)
          }
          else {
            // determine where to add
            const itemBelow = allItems[insertIndex];
            if (itemBelow.innerIndex == 0) {
                draft.pages[itemBelow.pageIndex].unshift(message);
            }
            else {
                draft.pages[itemBelow.pageIndex].splice(itemBelow.innerIndex-1, 0, message);
            }
            
          }
        })
        : oldData
      },
    );
}

const resetStockChart = (queryClient:QueryClient) => {
    queryClient.invalidateQueries({
        predicate: ({queryKey}) =>
          queryKey[0] == 'graphData' && 
          [GraphRange.DAY_1, GraphRange.DAY_5].some(x => x == (queryKey[1] as GraphQueryKey).graphRange),
      })
    queryClient.invalidateQueries(["getCompanyHighLowAndChanges"]);
}

const changeGraphCache = (messageArr:PriceUpdateWebSocket[], queryClient:QueryClient) => {
    const map = new Map(messageArr.map(x => [x.isin, { price: x.price, date: x.priceTime, volume: 0 }]));
    // graph ranges where the new graph point should be appended. the rest
    const appendGraphRanges = [GraphRange.DAY_1, GraphRange.DAY_5];
    queryClient.setQueriesData<GraphData>(
      {
        predicate: ({ queryKey }) => {
          if (queryKey[0] === 'graphData') {
            const graphQueryKey = queryKey[1] as GraphQueryKey;
            return (appendGraphRanges.includes(graphQueryKey.graphRange) && map.has(graphQueryKey.isin))
          }
          return false;
        }
      },
      (oldData:GraphData|undefined) => {
        if (!oldData) return;
  
        const graphUpdate = map.get(oldData?.isin)
        if (!graphUpdate) return oldData;
  
        return produce(oldData, draftData => {
          draftData.data.push({
            price: graphUpdate.price,
            volume: graphUpdate.volume,
            date: graphUpdate.date,
          });
        });
      }
    );

    //remaining are the graph ranges where graph point should replace the currently last graph point
    queryClient.setQueriesData<GraphData>(
      {
        predicate: ({ queryKey }) => {
          if (queryKey[0] === 'graphData') {
            const graphQueryKey = queryKey[1] as GraphQueryKey;
            return (!appendGraphRanges.includes(graphQueryKey.graphRange) && map.has(graphQueryKey.isin))
          }
          return false;
        }
      },
      (oldData:GraphData|undefined) => {
        if (!oldData) return;
  
        const graphUpdate = map.get(oldData?.isin)
        if (!graphUpdate) return oldData;
  
        return produce(oldData, draftData => {
          draftData.data[draftData.data.length - 1] = {
            price: graphUpdate.price,
            volume: graphUpdate.volume,
            date: graphUpdate.date,
          };
        });
      }
    );
}

const changeStockfeedCache = (messageArr:ICompany[], queryClient:QueryClient) => {
    const map = new Map(messageArr.map(x => [x.isin, x]));
    queryClient.setQueriesData<ICompany[]>(
      { 
        predicate: function ({ queryKey }) {
          return (queryKey[0] === 'getSimpelShare')
        }
      },
      (oldData:ICompany[]|undefined) => {
        return (oldData?.find(x => map.has(x.isin))) 
        ? produce(oldData, draft => {
          for (let i = 0; i < draft.length; i++) {
            const entry = draft[i];
            const newEntry = map.get(entry.isin)
            // skip if no update
            if (!newEntry) continue;
            
            // calculate if animate property is increase or decrease
            // we do it on the Map<> object so we only have to do it once, in case we have several news feeds
            //no need to do it when if animate already set
            if (!newEntry.animate && newEntry.shareprice && entry.shareprice) {
              const np = newEntry.shareprice.price;
              const op = entry.shareprice.price;
              if (np !== op) {
                if (np > op) newEntry.animate = 'increase';
                else newEntry.animate = 'decrease';
              }
            }
            draft[i] = newEntry;
          }
        })
        : oldData
      });
      useStockWindowStore.getState().setLastUpdate(new Date());
  
      // unset animate after 3000 ms
      setTimeout(() => {
        queryClient.setQueriesData<ICompany[]>(
          { 
            predicate: function ({ queryKey }) {
              return (queryKey[0] === 'getSimpelShare')
            }
          },
          (oldData:ICompany[]|undefined) => {
            return oldData
            ? produce(oldData, draft => {
              for (let i = 0; i < draft.length; i++) {
                draft[i].animate = undefined;
              }
            })
            : oldData
          });
      }, 3000)
    }

function compareKronologisk(a:Date, b:Date) {
    return(a < b) ? -1 : 1;
  }